import React, { useEffect, useRef, useState } from 'react';
import { defaultColors, styled } from "../../stitches.config";
import { bodyLarge, body } from "../commonStyles/Fonts";
import { withThemeContext } from "../components/StitchesThemeProvider";
import CopyButton from "../components/CopyButton"

import ShareIcon from "../assets/icons/socialbutton-share.svg";
import ShareIconBlack from "../assets/icons/socialbutton-share-black.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/social/facebook.svg?react";
import { ReactComponent as TwitterIcon } from "../assets/icons/social/twitter.svg?react";

import { ReactComponent as ShareIconNav } from "../assets/icons/nav/article-nav/share.svg?react";




interface SocialButtonProps {
  url: string;
  number?: number;
  showText?: boolean;
  isDarkMode?: boolean;
  isDarkModeNotPrimary?: boolean
  showShare?: boolean;
  isNavArticle?: boolean;
}


const SocialButton: React.FC<SocialButtonProps> = ({
  number = 0,
  isDarkMode,
  isDarkModeNotPrimary,
  url,
  showShare = false,
  showText = false,
  isNavArticle = false,
  ...props
}) => {
  const type = "share";
  const isNumberMoreThenZero = number > 0;

  const [isShareActive, setShareActive] = useState(showShare);
  const toggleShare = () => {
    setShareActive(!isShareActive);
  };
  console.log(isShareActive)
  return (
    <StyledSocialButton className="social-btn-share">
      {isNavArticle ?
        <>
          <SingleIcon onClick={toggleShare} >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0002 1.66667C14.0797 1.66667 13.3335 2.41286 13.3335 3.33333C13.3335 4.25381 14.0797 5 15.0002 5C15.9206 5 16.6668 4.25381 16.6668 3.33333C16.6668 2.41286 15.9206 1.66667 15.0002 1.66667ZM11.6668 3.33333C11.6668 1.49238 13.1592 0 15.0002 0C16.8411 0 18.3335 1.49238 18.3335 3.33333C18.3335 5.17428 16.8411 6.66667 15.0002 6.66667C14.01 6.66667 13.1206 6.23491 12.5101 5.5494L7.24158 8.71051C7.40813 9.10717 7.50016 9.54284 7.50016 10C7.50016 10.4573 7.40808 10.8931 7.24145 11.2898L12.5099 14.4509C13.1204 13.7652 14.0098 13.3333 15.0002 13.3333C16.8411 13.3333 18.3335 14.8257 18.3335 16.6667C18.3335 18.5076 16.8411 20 15.0002 20C13.1592 20 11.6668 18.5076 11.6668 16.6667C11.6668 16.4159 11.6945 16.1717 11.747 15.9368L6.224 12.623C5.65743 13.068 4.94312 13.3333 4.16683 13.3333C2.32588 13.3333 0.833496 11.841 0.833496 10C0.833496 8.15905 2.32588 6.66667 4.16683 6.66667C4.94325 6.66667 5.65768 6.93213 6.22429 7.37723L11.7471 4.06357C11.6945 3.82856 11.6668 3.58418 11.6668 3.33333ZM4.16683 8.33333C3.24635 8.33333 2.50016 9.07953 2.50016 10C2.50016 10.9205 3.24635 11.6667 4.16683 11.6667C5.0873 11.6667 5.8335 10.9205 5.8335 10C5.8335 9.07953 5.0873 8.33333 4.16683 8.33333ZM13.3335 16.6667C13.3335 15.7462 14.0797 15 15.0002 15C15.9206 15 16.6668 15.7462 16.6668 16.6667C16.6668 17.5871 15.9206 18.3333 15.0002 18.3333C14.0797 18.3333 13.3335 17.5871 13.3335 16.6667Z" />
            </svg>

          </SingleIcon>
          {isShareActive &&
            <ShareLayer>
              <StyledShareBoxPopup onClick={toggleShare} className={body()}>
                <CopyButton shareButton={true} text={url} />
                <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(url)}><span><FacebookIcon /></span>Facebook</a>
                <a target="_blank" href={"https://twitter.com/intent/tweet?text=" + encodeURI(url)}><span><TwitterIcon /></span>Twitter</a>
                {/* <a><span><TwitterBlackIcon /></span>Link</a> */}
              </StyledShareBoxPopup>
              <ShareBg onClick={toggleShare} />
            </ShareLayer>
          }
        </>
        :
        <>
          <StyledSocialButtonIcon
            type={type}
            onClick={toggleShare}
            variant={isDarkMode ? isDarkModeNotPrimary ? "darkNotPrimary" : "dark" : undefined}
            className="social-btn-icon"
          >
            <StyledSocialButtonNumber className={bodyLarge()}>
              <span className={isNumberMoreThenZero ? "icon-padding" : ""}>{isNumberMoreThenZero && number}</span>
            </StyledSocialButtonNumber>
          </StyledSocialButtonIcon>
          {
            showText === true && (
              <StyledSocialButtonText className={bodyLarge()}>
                {type === "share" && <span className={"text"} onClick={toggleShare}>Udostępnij{isNumberMoreThenZero && ": "}</span>}
                {isNumberMoreThenZero && number}
              </StyledSocialButtonText>
            )
          }
          {
            isShareActive &&
            <>
              <StyledShareBox onClick={toggleShare} className={body()}>
                <CopyButton shareButton={true} text={url} />
                <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(url)}><span><FacebookIcon /></span>Facebook</a>
                <a target="_blank" href={"https://twitter.com/intent/tweet?text=" + encodeURI(url)}><span><TwitterIcon /></span>Twitter</a>
                {/* <a><span><TwitterBlackIcon /></span>Link</a> */}
              </StyledShareBox>
              <ShareBgOpacity onClick={toggleShare} />
            </>
          }
        </>
      }
    </StyledSocialButton >
  );
};

const SingleIcon = styled('div', {
  width: "20px",
  height: "20px",
  "svg": {
    "path": {
      fill: "$primary"
    },
  }
})


const StyledSocialButton = styled("div", {
  display: "flex",
  alignItems: "center",
  position: "relative",
  "& p": {
    margin: 0,
    color: "$grayscale100",
  },
  "& .text": {
    display: "none",
    "@bp4": {
      display: "block",
    },
  },
});

const StyledSocialButtonText = styled("p", {
  display: "none",
  "@bp4": {
    display: "flex",
    cursor: "pointer",
  },
  "&:hover": {
    textDecoration: "underline",
  }
});

const StyledSocialButtonNumber = styled('span', {
  color: "$grayscale0",
  '@bp4': {
    display: "none",
  },
  '.icon-padding': {
    paddingLeft: "24px",
  }
})


const StyledSocialButtonIcon = styled('button', {
  backgroundColor: "$grayscale100",
  width: "max-content",
  padding: "4px 12px 4px 10px",
  borderRadius: "50px",
  borderWidth: 0,
  backgroundRepeat: "no-repeat",
  cursor: "pointer",
  minWidth: "32px",
  minHeight: "32px",
  transition: "0.2s",
  "&:hover": {
    backgroundColor: "$primary",
  },
  '@bp4': {
    padding: "0",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginRight: "10px",
  },
  variants: {
    type: {
      share: {
        backgroundImage: `url(${ShareIcon})`,
        backgroundSize: "18px",
        backgroundPosition: "8px",
        '@bp4': {
          backgroundPosition: "center",
        },
      },
    },
    variant: {
      dark: {
        backgroundColor: defaultColors.primary,
      },
      darkNotPrimary: {
        backgroundColor: "$grayscale25",
        backgroundImage: `url(${ShareIconBlack})`,
      }
    },
  },
});

const StyledShareBox = styled('div', {
  border: "1px solid $grayscale25",
  position: "absolute",
  zIndex: 10,
  bottom: "40px",
  background: "$grayscale0",
  width: "120px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "15.5px 0 2px 12.5px",
  "@bp4": {
    left: "-88px",
    bottom: "60px",
  },
  "@bp5": {
    left: "0px",
  },
  "& button, & a": {
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
    color: "$grayscale75",
    textDecoration: "none",
    borderWidth: "0",
    transition: "0.3s",
    margin: "0 0 12px 0 !important",
    padding: 0,
    width: "auto !important",
    height: "auto !important",
    "&:hover": {
      color: "$primary"
    },
    "& span": {
      width: "12px",
      height: "12px",
    },
    "& svg": {
      width: "12px",
      height: "12px",
      marginRight: "6px !important",
    }
  }
})

const StyledShareBoxPopup = styled('div', {
  border: "1px solid $grayscale25",
  position: "fixed",
  bottom: "40px",
  top: "calc(50% - 60px)",
  left: "calc(50% - 60px)",
  transform: "translate(-50 %, -50 %)",
  background: "$grayscale0",
  width: "120px",
  height: "120px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "15.5px 0 2px 12.5px",
  "& button, & a": {
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
    color: "$grayscale75",
    textDecoration: "none",
    borderWidth: "0",
    transition: "0.3s",
    margin: "0 0 12px 0 !important",
    padding: 0,
    width: "auto !important",
    height: "auto !important",
    "&:hover": {
      color: "$primary"
    },
    "& span": {
      width: "12px",
      height: "12px",
    },
    "& svg": {
      width: "12px",
      height: "12px",
      marginRight: "6px !important",
    }
  }
})
const ShareBgOpacity = styled('div', {
  position: "fixed",
  top: "118px",
  width: "100vw",
  height: "calc(100vh - 52px)",
  left: 0,
  opacity: "0.1",
  zIndex: "1",
})
const ShareLayer = styled('div', {
  margin: 0,
  position: "absolute",
  top: "52px",
  left: 0,
  width: "100vw",
  height: "calc(100vh - 52px)",
  zIndex: "2",
})
const ShareBg = styled('div', {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "black",
  opacity: "0.5",
  zIndex: "-1",
})

export default withThemeContext(SocialButton);
