import * as React from "react"
import { styled } from "../../../../../../stitches.config";
import AnimateHeight, { Height } from 'react-animate-height';

import { ReactComponent as ExpandBar } from "../../../../../../assets/img/natemat/pasek_rozwin.svg?react";
import ExpandBarBizon from "./ExpandBarBizon"


type TArrow = {
    direction: number | string;
    bottomPosition?: string;
}

export enum AnimateWrapperButton {
    StandardArrow,
    ShowMore
}

interface IAnimateHeightHOC {
    condition: boolean;
    children: React.ReactElement;
}

interface IProps {
    height?: number | string;
    buttonType?: AnimateWrapperButton;
    type?: AnimateWrapperButton
    visibility?: boolean;
    childrenRef?: any,
    onChange?: () => void;
}


const setButton = (type: AnimateWrapperButton, params: any) => {
    let { wrapHeight, height, setHeight, setButtonVisibility } = params;
    switch (+type) {
        case AnimateWrapperButton.StandardArrow:
            return <ArrowButton
                css={wrapHeight == "auto" ? { transform: "translateX(-50%) rotate(180deg)" } : {}}
                onClick={() => setHeight(wrapHeight === height ? "auto" : height)}>
                <span></span>
            </ArrowButton>
        case AnimateWrapperButton.ShowMore:
            return <ArrowButton2
                onClick={() => {
                    setHeight("auto")
                    setTimeout(() => setButtonVisibility(false), 500);
                }}>
                <ExpandBarBizon />
                {/* <button><span>:</span> Rozwiń</button> */}
            </ArrowButton2>
    }
}

const AnimateHeightWrapper: React.FC<React.PropsWithChildren<IProps>> = ({ children, height = 100, type = AnimateWrapperButton.StandardArrow, visibility = false, ...props }) => {
    const [wrapHeight, setHeight] = React.useState<Height>(height as Height);
    const [buttonVisibility, setButtonVisibility] = React.useState<boolean>(visibility);

    React.useEffect(() => {
        setButtonVisibility(visibility)
    }, [visibility])

    return (<Wrap>
        <AnimateHeight className={visibility ? "" : "auto-height"}
            duration={500}
            height={wrapHeight}>
            <div ref={props.childrenRef}>
                {children}
            </div>
            {buttonVisibility && <ArrowOverlay
                className="animate-height-wrapper__overlay"
                type={type}
                css={wrapHeight == "auto" ? {
                    bottom: "-13px"
                } : {}}
            />}
        </AnimateHeight>
        {buttonVisibility && setButton(type, { wrapHeight, height, setHeight, setButtonVisibility })}
    </Wrap >)
}

const Wrap = styled("div", {
    position: "relative",
    paddingBottom: "30px",
    overflow: "hidden",
    ".auto-height": {
        height: "auto !important"
    }
});

const ArrowOverlay = styled("div", {
    height: "50px",
    position: "absolute",
    bottom: "60px",
    left: 0,
    right: 0,
    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), $grayscale0 100%)",
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 100%);*/
    /* opacity: ${props => props.direction === "auto" ? "0" : "1"}; */
    transition: "bottom 500ms",
    variants: {
        "type": {
            [AnimateWrapperButton.StandardArrow]: {
                bottom: "30px"
            },
            [AnimateWrapperButton.ShowMore]: {

            }
        }
    },
});

const ArrowButton = styled("button", {
    position: "absolute",
    zIndex: 3,
    padding: "0",
    width: "24px",
    height: "24px",
    left: "50%",
    right: "50%",
    bottom: "3px",
    borderRadius: "50%",
    border: "1px solid #979797",
    backgroundColor: "transparent",
    outline: "0",
    transform: "translateX(-50%) rotate(0deg)",
    transition: "all 500ms",
    "&:hover": {
        borderColor: "$primary"
    },
    "span": {
        position: "absolute",
        content: "''",
        top: "4px",
        left: "6.5px",
        width: "0.625em",
        height: "0.625em",
        borderTop: "0.025em solid #979797",
        borderRight: "0.025em solid #979797",
        transform: "rotate(135deg)"
    }
})

const ArrowButton2 = styled("div", {
    width: "100%",
    height: "26px",
    zIndex: 55,
    marginBottom: "5px",
    cursor: "pointer",
    span: {
        fontWeight: "bold",
        fontSize: "1.5em"
    },
    button: {
        width: "100px",
        height: "25px",
        position: "absolute",
        color: "#ffffff",
        fontWeight: 700,
        background: "#ff6400",
        border: "none",
        fontSize: "0.8em",
        borderRadius: "5px",
        borderBottomRightRadius: "0",
        marginLeft: "-5px",
        textAlign: "left",
        lineHeight: "22px",
        letterSpacing: "0.15em",
        textTransform: "uppercase",
        display: "none"
    }
});

export const WithAnimateHeight: React.FC<IAnimateHeightHOC> = ({ condition, children }) => (condition ? <AnimateHeightWrapper>{children}</AnimateHeightWrapper> : children)

export default AnimateHeightWrapper